<template>
  <div class="flex items-center">
    <div>
      <erp-select :options="options" v-model="criterio.comparativo" />
    </div>
    <div class="m-l">
      <erp-select placeholder="Selecione" v-model="criterio.value" :options="tiposFiltered"
                  :loading="loadingTipos">
        <template v-slot:itemLabel="props">
          <span class="erp-select-level" :class="{'font-bold': Number(props.opt.level) === 0}" :style="'padding: 0 0 0 ' + (10 * Number(props.opt.level)) + 'px' ">{{props.opt.label}}</span>
        </template>
      </erp-select>
    </div>
  </div>
</template>

<script>
import {equals} from './matches/equals'
import {ErpSelect} from 'uloc-vue-plugin-erp'
import {listTiposBem} from '../../../../domain/cadastro/services'
import {treeCheck} from '../../../cadastro/components/tiposbem/helpers/treeCheck'
import criteriaMixin from './criteriaMixin'

export default {
  name: 'TipoBem',
  mixins: [criteriaMixin],
  props: {
    criterio: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      options: [
        ...equals
      ],
      loadingTipos: false,
      tipos: []
    }
  },
  computed: {
    tiposFiltered () {
      return this.treeCheck(this.tipos)
    }
  },
  mounted () {
    this.loadingTipos = true
    listTiposBem(200, 1, null, true)
      .then(({data}) => {
        this.tipos = data
        this.loadingTipos = false
      })
      .catch(error => {
        console.error(error)
      })
  },
  methods: {
    loadedComitente (data) {
      console.log('Loaded', data)
    },
    treeCheck: treeCheck,
  },
  components: {ErpSelect}
}
</script>
